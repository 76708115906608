import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faBook,
  faBrain,
  faCheck,
  faCircleInfo,
  faClipboard,
  faFeatherPointed,
  faHome,
  faMagnifyingGlass,
  faPalette,
  faRotateLeft,
  faRotateRight,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faBook,
  faBrain,
  faCheck,
  faCircleInfo,
  faClipboard,
  faFacebook,
  faFeatherPointed,
  faHome,
  faMagnifyingGlass,
  faPalette,
  faRotateLeft,
  faRotateRight,
  faX,
  faXmark,
  faYoutube
);

createApp(App)
  .component("fa", FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount("#app");
