<template>
  <div class="ending">
    <div class="declined" v-if="word.declension">
      <div v-if="singularDisplayed && !pluralOnly">
        <h4 class="number">Singular</h4>
        <table class="declension">
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              nom.
            </td>
            <td v-else class="right">nom.</td>
            <td v-if="word.english === 'Jesus'" class="left">
              {{ word.declined.nominative }}
            </td>
            <td v-else class="left">
              {{ word.latin.substring(0, word.latin.indexOf(",")) }}
            </td>
          </tr>
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              gen.
            </td>
            <td v-else class="right">gen.</td>
            <td v-if="word.english === 'Jesus'" class="left">
              {{ word.declined.dative }}
            </td>
            <td v-else class="left">
              {{ word.stem }}{{ noun.genitive.singular }}
            </td>
          </tr>
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              dat.
            </td>
            <td v-else class="right">dat.</td>
            <td v-if="word.english === 'Jesus'" class="left">
              {{ word.declined.genitive }}
            </td>
            <td v-else class="left">
              {{ word.stem }}{{ noun.dative.singular }}
            </td>
          </tr>
          <!-- START: Handling Singular 3rd Declension Neuter -->
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              acc.
            </td>
            <td v-else class="right">acc.</td>
            <td v-if="word.english === 'Jesus'" class="left">
              {{ word.declined.accusative }}
            </td>
            <td v-else-if="thirdNeuter" class="left">
              {{ word.latin.substring(0, word.latin.indexOf(",")) }}
            </td>
            <td v-else class="left">
              {{ word.stem }}{{ noun.accusative.singular }}
            </td>
          </tr>
          <!-- END: Handling Singular 3rd Declension Neuter -->
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              abl.
            </td>
            <td v-else class="right">abl.</td>
            <td v-if="word.english === 'Jesus'" class="left">
              {{ word.declined.ablative }}
            </td>
            <td v-else-if="isMare" class="left">{{ word.stem }}ī</td>
            <td v-else class="left">
              {{ word.stem }}{{ noun.ablative.singular }}
            </td>
          </tr>
        </table>
      </div>
      <!-- Plural Declension -->
      <div v-else>
        <h4 class="number">Plural</h4>
        <table class="declension">
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              nom.
            </td>
            <td v-else class="right">nom.</td>
            <td class="left">
              {{ word.stem }}<span v-if="isMare">i</span
              >{{ noun.nominative.plural }}
            </td>
          </tr>
          <!-- START: Handling Plural 3rd Declension -ium Endings -->
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              gen.
            </td>
            <td v-else class="right">gen.</td>
            <td class="left">
              {{ word.stem }}<span v-if="iumEnding">i</span
              ><span v-else-if="isMare">i</span>{{ noun.genitive.plural }}
            </td>
          </tr>
          <!-- END: Handling Plural 3rd Declension -ium Endings -->
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              dat.
            </td>
            <td v-else class="right">dat.</td>
            <td class="left">{{ word.stem }}{{ noun.dative.plural }}</td>
          </tr>
          <!-- START: Handling Plural 3rd Declension Neuter -->
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              acc.
            </td>
            <td v-else class="right">acc.</td>
            <td v-if="thirdNeuter" class="left">
              {{ word.stem }}<span v-if="isMare">i</span
              >{{ noun.accusative.plural }}
            </td>
            <td v-else class="left">
              {{ word.stem }}{{ noun.accusative.plural }}
            </td>
          </tr>
          <!-- END: Handling Plural 3rd Declension Neuter -->
          <tr>
            <td
              v-if="
                word.latin.substring(0, word.latin.indexOf(',')).length > 10
              "
              class="right shifted"
            >
              abl.
            </td>
            <td v-else class="right">abl.</td>
            <td class="left">{{ word.stem }}{{ noun.ablative.plural }}</td>
          </tr>
        </table>
      </div>
      <div v-if="!singularOnly && !pluralOnly" class="toggleDeclension">
        <button
          class="declensionButtons"
          @click="singularDisplayed = !singularDisplayed"
        >
          View
          <span v-if="singularDisplayed">Plural</span>
          <span v-else>Singular</span>
        </button>
      </div>
      <div v-else>
        <div><br /><br /></div>
      </div>
    </div>
    <button class="declensionButtons back" @click="$emit('toggle')">
      <fa icon="angle-left" />
    </button>
  </div>
</template>

<script>
import nouns from "@/json/declensionsNouns.json";
import { data } from "@/store/state";

export default {
  props: {
    word: Object,
  },
  emits: ["toggle"],
  data() {
    return {
      singularDisplayed: true,
      defaultNoun: {
        nominative: {},
        genitive: {},
        dative: {},
        accusative: {},
        ablative: {},
      },
      thirdNeuter: null,
      iumEnding: null,
      isMare: null,
      singularOnly: null,
      pluralOnly: null,
    };
  },
  computed: {
    nouns() {
      return data.nouns;
    },
    noun() {
      let e = this.word;
      let noun = this.nouns.find((noun) => {
        if (e.stem && e.stem === "loc") {
          return (
            noun.nominative.plural === "a" &&
            noun.genitive.plural === "ōrum" &&
            noun.dative.plural === "īs" &&
            noun.accusative.plural === "a" &&
            noun.ablative.plural === "īs"
          );
        } else {
          return noun.gender === e.gender && noun.name[0] === e.declension[0];
        }
      });
      return noun || this.defaultNoun;
    },
  },
  methods: {
    async loadNouns() {
      data.nouns = nouns;
      //   if (!data.nouns.length) {
      //     const res = await WordService.getNouns();
      //     data.nouns = res.data.nouns;
      //   }
    },
    async isThirdNeuter() {
      return (this.thirdNeuter =
        this.word.declension === "3rd" && this.word.gender === "neuter");
    },
    async getsIumEnding() {
      return (this.iumEnding =
        this.word.stem === "fīn" ||
        this.word.stem === "caed" ||
        this.word.stem === "nāv" ||
        (this.word.declension === "3rd" &&
          this.word.gender !== "neuter" &&
          !this.word.exception &&
          !this.word.stem.slice(-2).includes("a") &&
          !this.word.stem.slice(-2).includes("ā") &&
          !this.word.stem.slice(-2).includes("e") &&
          !this.word.stem.slice(-2).includes("ē") &&
          !this.word.stem.slice(-2).includes("i") &&
          !this.word.stem.slice(-2).includes("ī") &&
          !this.word.stem.slice(-2).includes("o") &&
          !this.word.stem.slice(-2).includes("ō") &&
          !this.word.stem.slice(-2).includes("u") &&
          !this.word.stem.slice(-2).includes("ū")));
    },
    async checkMare() {
      return (this.isMare = this.word.latin === "mare, maris");
    },
    checkSingularOnly() {
      // words that have "singular" in their "note"
      if (this.word.note) {
        return (this.singularOnly = this.word.note.includes("singular"));
      }
    },
    checkPluralOnly() {
      // words that have "plural" in their "note"
      if (this.word.note) {
        return (this.pluralOnly = this.word.note.includes("plural"));
      }
    },
  },

  created() {
    this.loadNouns();
  },
  mounted() {
    this.isThirdNeuter();
    this.getsIumEnding();
    this.checkMare();
    this.checkSingularOnly();
    this.checkPluralOnly();
  },
};
</script>

<style scoped>
table {
  table-layout: fixed;
  min-width: 250px;
  max-width: 320px;
  margin: auto;
}
.ending {
  position: inherit;
}
th {
  font-style: italic;
}
td,
th {
  padding: 2px 4px;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.shifted {
  width: 35%;
}
.center {
  text-align: center;
}
.declension {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.65em;
}
.number {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 1.75em;
}
.toggleDeclension {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 10px auto 0 auto;
}
.declensionButtons {
  margin-top: 5px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
  transition: 0.3s ease;
  border: 1px transparent solid;
}
.back {
  position: fixed;
  left: 15px;
  bottom: 15px;
  font-size: 1em;
  background-color: transparent;
  color: #695acdc0;
  border: 1px #695acdc0 solid;
  box-shadow: none;
}
@media (hover: hover) {
  .declensionButtons:hover,
  .back:hover {
    color: white;
    border: 1px transparent solid;
    background-color: #695acdc0;
  }
}
</style>
