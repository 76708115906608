<template>
  <div class="questionDiv">
    <h1
      class="questionHeader"
      style="
        margin: 0px auto 10px auto;
        padding: 5px 15px;
        max-width: 380px;
        background-color: #b95000;
        color: white;
      "
    >
      Sample Quiz
    </h1>
    <section class="questionSection" v-if="!quizCompleted">
      <div class="questionInfo">
        <span class="question">{{ getCurrentQuestion.question }}</span>
        <!-- <span class="score">Score: {{ score }}/{{ questions.length }}</span> -->
      </div>

      <div class="options">
        <label
          v-for="(option, index) in getCurrentQuestion.options"
          :key="option"
          :for="'option' + index"
          :class="`option ${
            getCurrentQuestion.selected == index
              ? index == getCurrentQuestion.answer
                ? 'correct'
                : 'wrong'
              : ''
          } ${
            getCurrentQuestion.selected != null &&
            index != getCurrentQuestion.selected
              ? 'disabled'
              : ''
          }`"
        >
          <input
            type="radio"
            :id="'option' + index"
            :name="getCurrentQuestion.index"
            :value="index"
            v-model="getCurrentQuestion.selected"
            :disabled="getCurrentQuestion.selected"
            @change="SetAnswer"
          />
          <span class="select">{{ option }}</span>
        </label>
      </div>

      <button
        id="next"
        @click="NextQuestion"
        :disabled="!getCurrentQuestion.selected"
      >
        {{
          getCurrentQuestion.index == questions.length - 1
            ? "Finish"
            : getCurrentQuestion.selected == null
            ? "Select an option"
            : "Next question"
        }}
      </button>
    </section>

    <section class="finishedSection" v-else>
      <br />
      <h2>You have finished the quiz!</h2>
      <p class="scoreDisplayed">
        Your score is {{ Math.round((score / questions.length) * 100) }}%
      </p>
      <h3>You answered {{ score }}/{{ questions.length }} correctly.</h3>
    </section>
    <div style="display: flex; flex-direction: row">
      <div class="btn" @click="$router.push({ name: 'Quizzes' })">
        <fa icon="arrow-left" />
        <h5 class="mtQuarter mb0">Quiz Type</h5>
      </div>
      <!-- <div class="btn" @click="$router.go()">
        <fa icon="rotate-left" />
        <h5 class="mtQuarter mb0">Start Over</h5>
      </div> -->
    </div>
  </div>
</template>

// https://youtu.be/6cXWWOxrZiw
<script setup>
import { ref, computed } from "vue";
const questions = ref([
  {
    question: "What is The Latinator?",
    answer: 0,
    options: [
      "A web app that helps Latin learners",
      "A Latin 'dance off-inator'",
      "A cyborg assassin from the future",
    ],
    selected: null,
  },
  {
    question: "Why is The Latinator used?",
    answer: 2,
    options: [
      "To learn the platypus dance ",
      "One Reason: To Destroy Romans",
      "To empower parents and students",
    ],
    selected: null,
  },
  {
    question: "Who created The Latinator?",
    answer: 1,
    options: ["Dr. Heinz Doofenshmirtz", "Jason Kelske", "Skynet"],
    selected: null,
  },
]);
const quizCompleted = ref(false);
const currentQuestion = ref(0);
const score = computed(() => {
  let value = 0;
  questions.value.map((q) => {
    if (q.selected != null && q.answer == q.selected) {
      console.log("correct");
      value++;
    }
  });
  return value;
});
const getCurrentQuestion = computed(() => {
  let question = questions.value[currentQuestion.value];
  question.index = currentQuestion.value;
  return question;
});
const SetAnswer = (e) => {
  questions.value[currentQuestion.value].selected = e.target.value;
  e.target.value = null;
};
const NextQuestion = () => {
  if (currentQuestion.value < questions.value.length - 1) {
    currentQuestion.value++;
    return;
  }

  quizCompleted.value = true;
};
</script>

<style scoped>
.questionDiv {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}
.questionHeader {
  font-size: 2rem;
  margin-bottom: 1rem;
}
section {
  background-color: #4e4398;
  color: white;
  padding: 1rem;
  width: 100%;
  max-width: 380px;
  border-radius: 5px;
  height: 350px;
}
.questionInfo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.questionInfo .question {
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
}
.questionInfo.score {
  color: rgb(255, 255, 255);
  font-size: 1.25rem;
}
.score {
  color: white;
  font-size: 1.2rem;
  font-weight: 100;
}
.scoreDisplayed {
  color: white;
}
.options {
  margin-bottom: 1rem;
}
.option {
  padding: 1rem;
  display: block;
  background-color: #282060;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
}
.option.correct {
  background-color: #09cd09;
  color: #282060;
}
.option.wrong {
  background-color: #ff5a5f;
  color: #282060;
}
.option:last-of-type {
  margin-bottom: 0;
}
.option.disabled {
  opacity: 0.5;
}
.option.disabled,
.option.correct,
.option.wrong {
  cursor: not-allowed;
}
.option input {
  display: none;
}
button {
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #f0eeff;
  color: #282060;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  border: #f0eeff solid 1px;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
p {
  color: white;
  font-size: 1.5rem;
  text-align: center;
}
.lineHeight {
  line-height: 1.05em;
}
.mt0 {
  margin-top: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mt1 {
  margin-top: 5px;
}
.mb1 {
  margin-bottom: 5px;
}
.mtQuarter {
  margin-top: 0.25em;
}
.btn {
  font-size: 1.5em;
  margin: 30px 10px 0 10px;
  max-width: 150px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #282060;
  cursor: pointer;
  color: #282060;
}
@media (hover: hover) {
  .btn:hover {
    transform: scale(1.05);
    color: white;
    background-color: #282060;
  }
  .option:hover {
    background-color: white;
    color: #282060;
  }
  .option.disabled:hover {
    background-color: #282060;
    color: white;
  }
  #next:hover {
    background-color: #282060;
    color: #f0eeff;
    border: #f0eeff solid 1px;
  }
  #next:disabled:hover {
    background-color: #f0eeff;
    color: #282060;
  }
  .option.correct:hover {
    background-color: #09cd09;
  }
  .option.wrong:hover {
    background-color: #ff5a5f;
  }
}
</style>
