<!--
Form came from this video:
https://youtu.be/ixOcve5PX-Q?t=1735
-->

<template>
  <form @submit.prevent="handleSubmit">
    <label>Chapter:</label>
    <input
      class="formField"
      type="number"
      name=""
      id=""
      required
      v-model="chapter"
    />
    <!-- <label>Book Author:</label>
    <input
      class="formField"
      type="text"
      name=""
      id=""
      required
      v-model="bookAuthor"
    /> -->
    <label>Section:</label>
    <input
      class="formField"
      type="number"
      name=""
      id=""
      required
      v-model="section"
    />
    <label>Tags:</label>
    <input
      class="formField"
      type="text"
      v-model="tempTag"
      @keyup.enter="addTag"
    />
    <p v-for="tag in tags" :key="tag" class="pill">
      <span @click="deleteTag(tag)">{{ tag }}</span>
    </p>
    <div class="submit">
      <button>Add a Note</button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      chapter: "",
      bookAuthor: "",
      section: "",
      tempTag: "",
      tags: [],
    };
  },
  methods: {
    addTag() {
      if (this.tempTag && !this.tags.includes(this.tempTag)) {
        this.tags.push(this.tempTag);
        this.tempTag = "";
      }
    },
    deleteTag(tag) {
      this.tags = this.tags.filter((item) => {
        return tag !== item;
      });
    },
    handleSubmit() {
      console.log("form submitted");
    },
  },
};
</script>

<style>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.formField {
  display: block;
  padding: 10px 6px;
  width: 100%;
  font-size: 1.25em;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #6a5acd;
}
.formField:last-child {
  margin-bottom: 30px;
}
.formField:focus {
  outline: none;
}
.pill {
  display: inline-block;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 10px 0 0;
  padding: 5px 15px;
  background-color: #eee;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 14px;
}
button {
  background: #6a5acd;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}
.submit {
  text-align: center;
}
</style>
