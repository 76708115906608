<template>
  <!-- ADD WordEndingsNouns AND RENAME FIRST PORTION TO INFO (OR SIMILAR) -->
  <WordInfo v-if="toggleInfo" @toggle="toggleInfo = !toggleInfo" :word="word" />
  <WordEndingsNouns
    v-else-if="word.partOfSpeech === 'noun'"
    @toggle="toggleInfo = !toggleInfo"
    :word="word"
  />
  <WordEndingsVerbs v-else @toggle="toggleInfo = !toggleInfo" :word="word" />
</template>

<script>
import WordInfo from "@/components/WordInfo.vue";
import WordEndingsNouns from "@/components/WordEndingsNouns.vue";
import WordEndingsVerbs from "@/components/WordEndingsVerbs.vue";

export default {
  props: {
    word: Object,
  },
  components: {
    WordInfo,
    WordEndingsNouns,
    WordEndingsVerbs,
  },
  data() {
    return {
      toggleInfo: true,
    };
  },
};
</script>
