<template>
  <div class="about">
    <h1>About</h1>
    <h2 class="lineHeight mt1">
      The Latinator helps parents
      <br />
      whose students use
      <br />
      Henle First Year Latin.
    </h2>
    <div class="JasonDiv">
      <img
        id="JasonImg"
        src="../assets/Headshot-square-bw-latinator.png"
        alt=""
      />
      <h3 class="lineHeight" id="JasonInfo">
        It was created, and is maintained,
        <br />
        by Jason Kelske,
        <br />
        an aspiring developer.
      </h3>
    </div>
    <Social style="font-size: 1.5em" />
    <hr />
    <div>
      <div class="homeBtn" @click="$router.push({ name: 'Home' })">
        <router-link :to="{ name: 'Home' }"
          ><fa icon="home" />
          <h5 class="mtQuarter mb0">Back Home</h5></router-link
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.about {
  margin: 4.5em auto 0 auto;
  max-width: 380px;
  color: #282060;
  height: 50vh;
}
#JasonDiv {
  display: flex;
  flex-direction: row;
}
#JasonImg {
  border-radius: 20%;
  box-shadow: 2px 2px 10px -5px #282060;
  width: 100px;
}
#JasonInfo {
  margin: 10px auto;
}
.lineHeight {
  line-height: 1.05em;
}
.mt0 {
  margin-top: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mt1 {
  margin-top: 5px;
}
.mb1 {
  margin-bottom: 5px;
}
.mtQuarter {
  margin-top: 0.25em;
}
.social {
  font-size: 1.75em;
}
.homeBtn {
  font-size: 1.5em;
  margin: 30px auto 0 auto;
  max-width: 150px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #282060;
  cursor: pointer;
}
@media (hover: hover) {
  .homeBtn:hover {
    transform: scale(1.05);
    color: white;
    background-color: #282060;
  }
}
</style>

<script>
import Social from "@/components/social/SocialLinks.vue";
export default {
  components: {
    Social,
  },
  mounted() {
    console.log("'AboutView' has been mounted");
  },
};
</script>
