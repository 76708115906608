<template>
  <div class="quizMain">
    <h1 v-if="this.$route.name === 'Quizzes'">Quizzes</h1>
    <h2
      v-if="this.$route.name === 'Quizzes'"
      style="
        margin: 0px auto 10px auto;
        padding: 5px;
        max-width: 380px;
        background-color: #b95000;
        color: white;
      "
    >
      Take a Sample Quiz?
    </h2>
    <section v-if="this.$route.name === 'Quizzes'">
      <div class="takeQuiz">
        <div class="btn x" @click="$router.push({ name: 'Home' })">
          <fa icon="xmark" />
          <h5 class="mtQuarter mb0">No, thanks</h5>
        </div>
        <div class="btn check" @click="$router.push({ name: 'Quiz' })">
          <fa icon="check" />
          <h5 class="mtQuarter mb0">Yes, please!</h5>
        </div>
      </div>
    </section>
    <Quiz v-if="this.$route.name === 'Quiz'" />
  </div>
</template>

<script>
import words from "@/json/vocabFirstYear.json";
import Quiz from "@/components/Quizzes/QuizMe.vue";
import { data } from "@/store/state";

export default {
  components: { Quiz },
  created() {
    this.loadWords();
  },
  methods: {
    loadWords() {
      data.words = words;
    },
  },
  computed: {
    words() {
      return data.words;
    },
  },
};
</script>

<style scoped>
.quizMain {
  margin-top: 70px;
}
.takeQuiz {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.lineHeight {
  line-height: 1.05em;
}
.mt0 {
  margin-top: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mt1 {
  margin-top: 5px;
}
.mb1 {
  margin-bottom: 5px;
}
.mtQuarter {
  margin-top: 0.25em;
}
.btn {
  font-size: 1.5em;
  margin: 30px 10px 0 10px;
  max-width: 150px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #282060;
  cursor: pointer;
  color: #282060;
}
@media (hover: hover) {
  .btn:hover {
    transform: scale(1.05);
    color: white;
    background-color: #282060;
  }
}
</style>
