import { ref, computed } from "vue";

export const collapsed = ref(false);
export const toggleSidebar = () => (collapsed.value = !collapsed.value);

export const SIDEBAR_WIDTH = 180;
export const SIDEBAR_WIDTH_COLLAPSED = 38;
export const sidebarWidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED}px`
);

export const lowered = ref(false);
export const toggleBottom = () => (lowered.value = !lowered.value);

export const BOTTOM_HEIGHT = 300;
export const BOTTOM_HEIGHT_LOWERED = 0;
export const bottomHeight = computed(
  () => `${lowered.value ? BOTTOM_HEIGHT : BOTTOM_HEIGHT_LOWERED}px`
);
