<template>
  <div class="infoBar" v-if="searchedWords.length < 498">
    <h4 v-if="searchedWords.length" class="matchedMain">
      <span class="clear" @click="$emit('clear')">Clear</span> the
      <span class="matchedWords">{{ searchedWords.length }}</span> word<span
        v-if="searchedWords.length !== 1"
        >s</span
      >
      in this search.
    </h4>
  </div>
</template>

<script>
export default {
  name: "InfoBar",
  props: {
    // words: Object,
    word: Object,
    searchedWords: Object,
  },
  emits: ["clear"],
};
</script>
<style scoped>
.infoBar {
  position: relative;
  z-index: 99;
}

@media (hover: hover) {
  .clear:hover {
    background-color: whitesmoke;
    color: #6a5acd;
    border: 1px solid #6a5acd;
  }
}
</style>
