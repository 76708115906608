<template>
  <div @click="toggleCard" class="word-link">
    <div class="word-card" :id="word.id + '_word-card'">
      <h1 class="main-term">
        {{ word.latin }}
        <span v-if="word.latin === 'in' && word.chapter === '4'"
          ><em>({{ word.note }})</em></span
        >
      </h1>
      <h4 style="margin: 0">
        Ch.
        <span v-if="word.chapter[0] === '0'"
          >{{ word.chapter.substring(1) }} / Pg. {{ word.page }}
        </span>
        <span v-else> {{ word.chapter }} / Pg. {{ word.page }} </span>
      </h4>
    </div>
    <div @click.stop class="infoModal" :class="[{ infoDisplay: invisible }]">
      <div class="english" v-if="word.english">
        <WordDisplay :word="word" />
        <div class="closeModal" @click="toggleCard">
          <p class="x">CLOSE</p>
        </div>
      </div>
    </div>
    <div
      @click.stop
      class="modalOverlay"
      :class="[{ infoDisplay: invisible }]"
    ></div>
  </div>
</template>

<script>
import WordDisplay from "@/components/WordDisplay.vue";

export default {
  name: "WordCard",
  data() {
    return {
      invisible: true,
    };
  },
  components: {
    WordDisplay,
  },
  props: {
    // words: Object,
    word: Object,
  },
  methods: {
    toggleCard: function () {
      this.invisible = !this.invisible;
      if (!this.invisible) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
  },
};
</script>

<style scoped>
.infoDisplay {
  display: none;
}

.infoModal {
  position: fixed;
  z-index: 25;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  background-color: white;
  width: 80vw;
  max-width: 420px;
  min-width: 300px;
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.8);
  font-size: 0.95em;
  transition: 0.3s all;
}

.closeModal {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  margin-top: 25px;
  height: 30px;
  padding: 0 10px;
  color: white;
  border: 1px #6a5acd solid;
  border-radius: 50px;
  cursor: pointer;
  background-color: #6a5acd;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.closeModal:hover {
  background: white;
  color: black;
  border: 1px black solid;
}

.x {
  position: relative;
  margin: 0;
  padding: 8px;
  font-size: 1.25em;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalOverlay {
  position: fixed;
  z-index: 13;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  background: hsl(250, 33%, 4%, 0.8);
  height: 100vh;
  width: 100vw;
}

.word-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: #2c3e50;
  text-decoration: none;
  max-width: 400px;
}

.word-card {
  background-color: white;
  padding: 20px;
  width: 70vw;
  max-width: 300px;
  cursor: pointer;
  border: 0.05px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.word-card:hover {
  background-color: #e5e2fc;
  border: 0.05px solid #c9c2f9;
  box-shadow: none;
}

.main-term {
  font-size: 1.8em;
  color: #6a5acd;
}

.latin-info {
  padding-top: 3px;
}

.main-term,
.latin-info {
  margin: 0;
}

.meaning {
  margin-bottom: 0;
}

.pospeech {
  margin-top: 0;
}

.chapter {
  border-radius: 0 0 4px 4px;
  padding: 5px 0;
  color: white;
  background-color: #6a5acd;
}

@media (max-width: 400px) {
  .infoModal {
    width: 85vw;
  }
}
</style>
