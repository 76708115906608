<template>
  <div class="main">
    <h1>This page doesn't exist.</h1>
  </div>
</template>

<style scoped>
.main {
  position: relative;
  top: 200px;
}
</style>
