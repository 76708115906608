<template>
  <!-- Account for irregular verbs WHERE "conjugation": null -->
  <div class="ending">
    <h2 class="conjugatedHeader">Present Indicative Active</h2>
    <h3 class="conjugatedNumber">Singular</h3>
    <div v-if="word.stem">
      <p class="conjugatedVerb">
        {{ word.stem + verb.active.indicative.present.singular.first }}
      </p>
      <!-- Irregular Verb, dō, no macros in singular 2nd Person -->
      <p v-if="word.latin === 'dō'" class="conjugatedVerb">
        {{ word.stem + "as" }}
      </p>
      <p v-else class="conjugatedVerb">
        {{ word.stem + verb.active.indicative.present.singular.second }}
      </p>
      <p class="conjugatedVerb">
        {{ word.stem + verb.active.indicative.present.singular.third }}
      </p>
    </div>
    <h3 class="conjugatedNumber">Plural</h3>
    <div v-if="word.stem">
      <!-- Irregular Verb, dō, no macros in plural 1st Person -->
      <p v-if="word.latin === 'dō'" class="conjugatedVerb">
        {{ word.stem + "amus" }}
      </p>
      <p v-else class="conjugatedVerb">
        {{ word.stem + verb.active.indicative.present.plural.first }}
      </p>
      <!-- Irregular Verb, dō, no macros in plural 2nd Person -->
      <p v-if="word.latin === 'dō'" class="conjugatedVerb">
        {{ word.stem + "atis" }}
      </p>
      <p v-else class="conjugatedVerb">
        {{ word.stem + verb.active.indicative.present.plural.second }}
      </p>
      <p class="conjugatedVerb">
        {{ word.stem + verb.active.indicative.present.plural.third }}
      </p>
    </div>
    <br />
    <button class="declensionButtons back" @click="$emit('toggle')">
      <fa icon="angle-left" />
    </button>
  </div>
</template>

<script>
import verbs from "@/json/conjugations.json";
import { data } from "@/store/state";

export default {
  data() {
    return {
      singularDisplayed: true,
      defaultVerb: {
        active: {},
      },
    };
  },
  props: {
    word: Object,
  },
  computed: {
    verbs() {
      return data.verbs;
    },
    verb() {
      let e = this.word;
      let verb = this.verbs.find((verb) => {
        if (e.stem && e.conjugation) {
          return verb.name[0] === e.conjugation[0];
        }
      });
      return verb || this.defaultVerb;
    },
  },
  methods: {
    async loadVerbs() {
      data.verbs = verbs;
    },
  },
  created() {
    this.loadVerbs();
  },
};
</script>

<style scoped>
.ending {
  position: inherit;
}
.back {
  position: fixed;
  left: 15px;
  bottom: 15px;
  font-size: 1em;
  background-color: transparent;
  color: #695acdc0;
  border: 1px #695acdc0 solid;
  box-shadow: none;
}
.conjugatedHeader {
  border-bottom: 2px solid #695acdc0;
  font-size: 1.75em;
  margin: 0 auto 5px auto;
}
.conjugatedNumber {
  font-size: 1.65em;
  margin: 10px auto 0 auto;
}
.conjugatedVerb {
  margin: 0 auto;
  font-size: 1.5em;
}
@media (hover: hover) {
  .declensionButtons:hover,
  .back:hover {
    color: white;
    border: 1px transparent solid;
    background-color: #695acdc0;
  }
}
</style>
