<template>
  <div>
    <!-- English -->
    <h1
      class="main-term"
      style="margin-top: 0; margin-left: 10px; margin-right: 10px"
    >
      {{ word.english }}
    </h1>
    <!-- Latin -->
    <h3 style="margin-top: 0; color: grey">
      <em>{{ word.latin }}</em>
    </h3>
    <!-- Part Of Speech -->
    <h2 class="pospeech" v-if="word.gender">
      {{ word.gender.toUpperCase() }}
      {{ word.partOfSpeech.toUpperCase() }}
    </h2>
    <h2 class="pospeech" v-else-if="word.page === '200'">
      <a href="https://youtu.be/UHnVVEqKmYA" target="_blank"
        ><img
          style="width: 260px"
          src="https://i.imgflip.com/qbm81.jpg?a463272"
          alt="Dr. Doofenshmirtz pointing at an inator"
      /></a>
    </h2>
    <h2 class="pospeech" v-else-if="word.partOfSpeech === 'verb'">
      <span v-if="word.verbType === 'tr.'">TRANSITIVE</span>
      <span v-else-if="word.verbType === 'intr.'">INTRANSITIVE</span>
      <span v-else>TR. & INTR. </span>
      {{ word.partOfSpeech.toUpperCase() }}
    </h2>
    <h2 class="pospeech" v-else>{{ word.partOfSpeech.toUpperCase() }}</h2>
    <!-- Declension or Conjugation -->
    <h2 v-if="word.declension">{{ word.declension }} Declension</h2>
    <h2 v-else-if="word.conjugation">{{ word.conjugation }} Conjugation</h2>
    <!-- Note -->
    <h3 v-if="word.note">NOTE: {{ word.note }}</h3>
    <!-- Hint -->
    <h3 v-if="word.hint && word.page === '200'" style="color: gray">
      <em>"{{ word.hint }}"</em>
    </h3>
    <h3 v-else-if="word.hint" style="color: gray">
      <em>HINT: {{ word.hint }}</em>
    </h3>
    <!-- Chapter & Page -->
    <h4 class="bookInfo" v-if="word.chapter[0] === '0'">
      CHAPTER: {{ word.chapter.substring(1) }} / PAGE: {{ word.page }}
    </h4>
    <h4 class="bookInfo" v-else>
      CHAPTER: {{ word.chapter }} | PAGE: {{ word.page }}
    </h4>
    <!-- Buttons -->
    <div
      v-if="
        (word.declension && word.stem) ||
        (word.partOfSpeech === 'verb' && word.stem)
      "
    >
      <hr />
      <div
        v-if="
          word.partOfSpeech === 'adjective' || word.partOfSpeech === 'pronoun'
        "
      >
        <h2 style="margin: 0">Coming Soon!</h2>
        <p style="margin: 0; font-size: 1.25em">👇👇👇👇👇</p>
      </div>
      <div
        v-if="
          word.partOfSpeech === 'noun' ||
          word.partOfSpeech === 'adjective' ||
          (word.partOfSpeech === 'verb' && word.stem)
        "
      >
        <!-- DECLINE & CONJUGATE NOUN BUTTON -->
        <!-- <button v-if="word.partOfSpeech === 'verb'" class="btnConjDecl">
          4PP
        </button> -->
        <button
          v-if="word.partOfSpeech === 'noun' || word.partOfSpeech === 'verb'"
          @click="$emit('toggle')"
          class="btnConjDecl"
        >
          <span v-if="word.partOfSpeech === 'noun'"> Decline It</span>
          <span v-else-if="word.partOfSpeech === 'verb'"> Conjugate It </span>
        </button>
        <!-- DECLINE (ADJ & PR) BUTTONS & CONJUGATE VERB BUTTONS ( NEED 3 SEPARATE BUTTONS ) -->
        <button v-else class="btnConjDecl btnDeactivated">
          <span v-if="word.partOfSpeech === 'adjective'">Decline It</span>
        </button>
      </div>
      <!-- <div v-else-if="word.partOfSpeech === 'verb' && !word.stem">
        <h3 class="message">Conjugating Begins in Ch. 9</h3>
      </div> -->
    </div>
    <div v-else>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    word: Object,
  },
  emits: ["toggle"],
};
</script>
<style scoped>
.main-term {
  line-height: 120%;
}
.btnConjDecl {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.25em;
  margin: 5px;
}
.btnDeactivated {
  opacity: 0.35;
  cursor: not-allowed;
}
</style>
