<template>
  <div class="director">
    <NewNote />
  </div>
</template>

<script>
import NewNote from "../components/NewNote.vue";
export default {
  components: { NewNote },
};
</script>

<style scoped>
.director {
  position: relative;
  top: 160px;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
</style>
