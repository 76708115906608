<template>
  <div>
    <nav class="header">
      <img
        class="main-logo"
        src="@/assets/logo-circle.png"
        alt="Caesar dressed as the Terminator"
      />
      <h1>The Latinator</h1>
    </nav>
  </div>
  <router-view />
</template>

<script>
// import SideBar from "@/components/sidebar/SideBar";
import { sidebarWidth } from "@/components/sidebar/state";
export default {
  // components: { SideBar },
  setup() {
    return { sidebarWidth };
  },
  mounted() {
    console.log(this.$route.path);
  },
};
</script>

<style>
body {
  background-color: whitesmoke;
  width: 100%;
  min-width: 250px;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 1em;
  width: 100%;
}

.header {
  background-color: #282060;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main-logo {
  height: 30px;
  margin-right: 10px;
}

nav {
  background-color: whitesmoke;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

nav a {
  font-size: 1.1em;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #6a5acd;
  font-weight: 900;
}

a,
a:focus,
a:active {
  font-size: 1.1em;
  text-decoration: none;
  color: inherit;
}

h1 {
  margin: 0;
}

.search {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  width: 200px;
  border-radius: 20px;
}

.term {
  font-weight: 900;
  margin: 0 auto 5px auto;
  max-width: 320px;
}

.definition {
  position: relative;
  align-content: center;
  margin: 0 auto 5px auto;
  max-width: 320px;
  font-weight: 900;
}

.deets {
  position: relative;
  width: 350px;
  margin: 200px auto 0 auto;
  align-items: center;
}

.statement {
  position: relative;
  margin: 10px;
  font-weight: 600;
}

.bookInfo {
  margin: 0;
}
</style>
