import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/AboutView.vue";
import Notes from "@/views/NoteView.vue";
import NotFound from "@/views/NotFound.vue";
import Quizzes from "@/components/Quizzes/QuizDisplay.vue";
import Quiz from "@/components/Quizzes/QuizMe.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    prop: Object,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/quizzes",
    name: "Quizzes",
    component: Quizzes,
    props: Object,
    children: [
      {
        path: "quiz",
        name: "Quiz",
        component: Quiz,
        props: true,
      },
    ],
  },
  {
    path: "/notes",
    name: "Notes",
    component: Notes,
  },
  // {
  //   path: "/word/:afterWord(.*)",
  //   redirect: (to) => {
  //     return { path: "/words/" + to.params.afterWord };
  //   },
  // },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/404/:resource",
    name: "404Resource",
    component: NotFound,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
