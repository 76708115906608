<template>
  <div>
    <a href="https://www.facebook.com/TheLatinator/" target="_blank"
      ><fa class="social" icon="fa-brands fa-facebook"
    /></a>
    <a
      href="https://www.youtube.com/@latinator/shorts?sub_confirmation=1"
      target="_blank"
      ><fa class="social" icon="fa-brands fa-youtube"
    /></a>
  </div>
</template>

<script>
export default {
  name: "SocialLinks",
};
</script>

<style scoped>
.social {
  margin: 20px;
  padding: 0;
  font-size: 1.75em;
}
@media (hover: hover) {
  .social:hover {
    transform: scale(1.1);
    opacity: 0.75;
  }
}
</style>
